/* global require */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


/* @REFACTOR: esse arquivo esta sendo usado por muito anos de forma diferente, tem codigo de todos os tipos e misturado
 o ideal e atualizar para tudo funcionar da mesma forma */

require('@rails/ujs').start();
require('@rails/activestorage').start();
import "./channels/**/*_channel.js";
require("chartkick/chart.js");

// Start Turbo
import { Turbo } from "@hotwired/turbo-rails";
Turbo.start();

import './modal-alert';
import './help';

import "flowbite/dist/flowbite.turbo.js";

// I18n Javascript support

import { I18n } from 'i18n-js';
window.I18n = I18n;

// ApexCharts

import ApexCharts from 'apexcharts'
window.ApexCharts = ApexCharts

HTMLElement.prototype.appendNewChild = function (tagName, attributes = {}, content = null) {
    let newElem = document.createElement(tagName);

    for (let attrName in attributes) {
        if (attributes.hasOwnProperty(attrName)) {
            newElem.setAttribute(attrName, attributes[attrName]);
        }
    }

    if (content) {
        newElem.innerHTML = content;
    }

    this.appendChild(newElem);
    return newElem;
};

HTMLElement.prototype.raiseEvent = function (eventName, data = null, bubbles = true, cancelable = true, composed = false) {
    let event = new CustomEvent(eventName, { detail: data, cancelable: cancelable, bubbles: bubbles, composed: composed });
    this.dispatchEvent(event);
};

function systemMessage(message, kind = 'danger') {
    let btn = document.getElementById('system-messages')
        .appendNewChild('div', { class: `alert alert-${kind}` }, message)
        .appendNewChild('button', { type: 'button', class: 'close' });
    btn.appendNewChild('span', null, '×');
    btn.dataset.dismiss = 'alert';
}

window.alert = systemMessage;

import Shortcut from './shortcuts';

document.addEventListener('turbo:load', () => {
    Shortcut.start();
});

import "trix"
import "@rails/actiontext"

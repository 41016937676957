import Rails from '@rails/ujs';
import { I18n } from 'i18n-js';

const showConfirmationDialog = (element) => {

  const confirmation_modal = modal();

  document.body.appendChild(confirmation_modal);

  confirmation_modal.show({ hideCloseButton: true }).then((result) => {
    if (result === 'true') {
      element.removeAttribute('data-confirm');
      confirmation_modal.remove();
      element.click();
    }
    if (result === 'false') {
      confirmation_modal.remove();
    }
  });

};

const modal = () => {
  const i18n = new I18n(I18n.translations,{ locale: I18n.locale });

  const modal = document.createElement('modal-dialog');
  const left_button = button({ slot: 'left', class: 'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm', text: i18n.t('modal.true'), resolve: true, data: 'true' });
  const right_button = button({ slot: 'right', class: 'w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm', text: i18n.t('modal.false'), resolve: true });

  modal.setAttribute('caption', i18n.t('delete'));
  modal.setAttribute('content', i18n.t('modal.text'))

  modal.appendChild(left_button);
  modal.appendChild(right_button);

  return modal;
};

const button = (options = {}) => {
  const button = document.createElement('button');
  if (options.slot) {
    button.setAttribute('slot', options.slot);
  }
  if (options.class) {
    button.className = options.class;
  }
  if (options.text) {
    button.innerHTML = options.text;
  }
  if (options.resolve) {
    button.dataset.resolve = options.resolve;
  }
  if (options.data) {
    button.dataset.data = options.data;
  }
  return button;
};

const allowAction = (element) => {
  if (element.getAttribute('data-confirm') === null) {
    return true;
  }

  showConfirmationDialog(element);
  return false;

};

function handleConfirm(element) {
  if (!allowAction(this)) {
    Rails.stopEverything(element);
  }
}

Rails.delegate(document, 'a[data-confirm]', 'click', handleConfirm);
